import {
  ClientAspectRatio,
  type DtoPersonality,
  EnumsDialogueGenerationType,
  type ModelsDialogue,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';
import { trainingDialogueSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/trainingDialogue';

import { apiService } from '../../../services/api-service';
import { uuidv4 } from '../../../utils/common';
import {
  DIALOGUE_MARKER_TYPES,
  type DialogueMarker,
  type DialogueMarkerImage,
  type DialogueMarkerType,
  type DialogueScriptSegment,
  type DialogueScriptSegmentType,
} from './types';

const emojiMap: string[] = [
  '',
  '1️⃣',
  '2️⃣',
  '3️⃣',
  '4️⃣',
  '5️⃣',
  '6️⃣',
  '7️⃣',
  '8️⃣',
  '9️⃣',
  '🔟',
];

export class DialogueUtils {
  static IsMarkerType(
    type: DialogueScriptSegmentType
  ): type is DialogueMarkerType {
    return DIALOGUE_MARKER_TYPES.includes(type as DialogueMarkerType);
  }

  static ParseScriptSegments(script: string): DialogueScriptSegment[] {
    const segments: DialogueScriptSegment[] = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(script, 'text/html');
    const walkNodes = (nodes: NodeList) => {
      nodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const textContent = node.textContent || '';
          if (textContent.trim()) {
            segments.push({ type: 'text', text: textContent });
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          if (element.tagName.toLowerCase() === 'mark') {
            const type = element.getAttribute('type') || 'trigger';
            const name = element.getAttribute('name') || '';

            if (type === 'image') {
              const query = element.getAttribute('query') || '';
              segments.push({ type: 'image', name, query });
            } else if (type === 'trigger') {
              segments.push({ type: 'trigger', name });
            } else if (type === 'tutor-question') {
              const question = element.getAttribute('question') || '';
              const finishCriteria =
                element.getAttribute('finishCriteria') || '';
              segments.push({
                type: 'tutor-question',
                name,
                question,
                finishCriteria,
              });
            }
          }
          // Recursively process child nodes
          if (element.childNodes.length > 0) {
            walkNodes(element.childNodes);
          }
        }
      });
    };

    walkNodes(doc.body.childNodes);
    return segments;
  }

  static ParseMarkers(script: string): DialogueMarker[] {
    const segments = DialogueUtils.ParseScriptSegments(script);
    return segments.filter((segment) =>
      DialogueUtils.IsMarkerType(segment.type)
    ) as DialogueMarker[];
  }

  static GetTriggerDisplayName(trigger: string): Nullable<string> {
    const match = trigger.match(/^trigger-(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10);
      if (number >= 1 && number <= 10) {
        return emojiMap[number];
      }
    }
    return null;
  }

  static DialogueToAISchema(dialogue: ModelsDialogue) {
    return {
      entries: dialogue.entries.map((entry) => ({
        script: entry.script,
        speakerId: entry.personalityId,
      })),
    };
  }

  static AISchemaToDialogue(args: unknown): ModelsDialogue {
    const schema = trainingDialogueSchema.parse(args);
    const dialogue = {
      entries: schema.entries.map((entry) => {
        return {
          id: uuidv4(),
          generationType:
            EnumsDialogueGenerationType.DialogueGenerationTypeClient,
          personalityId: entry.speakerId,
          script: entry.script,
        };
      }),
    };
    return dialogue;
  }

  static JoinSegments(segments: DialogueScriptSegment[]): string {
    return segments
      .map((segment) => {
        if (segment.type === 'text') {
          return segment.text;
        } else if (segment.type === 'image') {
          return `<mark type="image" name="${segment.name}" query="${segment.query}"></mark>`;
        } else if (segment.type === 'trigger') {
          return `<mark type="trigger" name="${segment.name}"></mark>`;
        }
        return '';
      })
      .join('');
  }

  static async FillImage(segment: DialogueMarkerImage) {
    if (segment.name) return segment;
    if (!segment.query) return segment;

    const media = await DialogueUtils.GenerateAndUploadImage(segment.query);
    return {
      ...segment,
      name: media.id,
    };
  }

  static async FillScriptImages(script: string): Promise<string> {
    const segments = DialogueUtils.ParseScriptSegments(script);
    const filledSegments = await Promise.all(
      segments.map((segment) => {
        if (segment.type === 'image') {
          return this.FillImage(segment);
        }
        return segment;
      })
    );
    return DialogueUtils.JoinSegments(filledSegments);
  }

  static async FillDialogueImages(
    dialogue: ModelsDialogue
  ): Promise<ModelsDialogue> {
    await Promise.all(
      dialogue.entries.map(async (entry) => {
        const script = await this.FillScriptImages(entry.script);
        entry.script = script;
      })
    );

    return dialogue;
  }

  /**
   * Generate an image using AI and upload it to the media server
   * @param query The text description to generate the image from
   * @returns The uploaded media object
   */
  static async GenerateAndUploadImage(query: string): Promise<Media> {
    try {
      // Generate image using AI
      const genResponse = await apiService.aiGeneral.generateImages({
        prompt: query,
        num: 1,
        aspectRatio: ClientAspectRatio.ASPECTRATIO_WIDE,
      });

      if (genResponse.data.images.length === 0) {
        throw new Error('No images generated');
      }

      // Convert base64 to blob
      const b64Data = genResponse.data.images[0].b64;
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });

      // Upload the generated image
      const uploadResponse = await apiService.media.upload(blob, {
        contentType: 'image/png',
      });

      return uploadResponse.data.media;
    } catch (error) {
      console.error('Failed to generate or upload image:', error);
      throw error;
    }
  }

  static ImportDialogueJSON(props: {
    jsonText: string;
    personalities: DtoPersonality[];
    defaultPersonalityId: string;
  }): ModelsDialogue {
    const { jsonText, personalities, defaultPersonalityId } = props;

    const rawDialogue = JSON.parse(jsonText);
    const dialogue = this.AISchemaToDialogue(rawDialogue);

    const personalityMap = new Map(personalities.map((p) => [p.id, p]));
    dialogue.entries.forEach((entry) => {
      entry.personalityId =
        personalityMap.get(entry.personalityId)?.avatarId ||
        defaultPersonalityId;
    });
    return dialogue;
  }
}
